// showMemberValidationForm(success,cancel,loaded) is from the AAA hosted script
// we need to create the callback functions success="successCallback" cancel="cancelCallback" loaded="loadedCallback"

// example response from widget
// {
//   "number": "4290140000000000",
//   "club": "014",
//   "level": "Plus",
//   "validationType": "Member Name",
//   "vendor": "certus"
// }

// global error element
const errorMsgElMember = document.querySelector('#errormsg-member');
const errorMsgElNonMember = document.querySelector('#errormsg-non-member');

// this handles setting the groupdId on the page
// handle setting the cookies and anything that tells the page that the user is a member
const handleSuccess = (res) => {
  console.log(res);

  const { club: clubCode } = res;

  // use default club groupId of the page if there's only one club on page
  let groupId = !aaaModal.askForZipcode ? aaaModal.cookieGroupId : null;

  // get corresponding club from clubs on page based on clubCode from API
  const club = aaaModal.clubsOnPage.find((club) => club.clubCode.includes(clubCode));

  const redirectToCart = (groupdId) => {
    errorMsgElMember.innerHTML = '';

    cart.setCookie('_up4gid', groupdId);

    // clearing the existing cart data
    cart.deleteCookie('cartId');
    sessionStorage.removeItem('localCart');
    sessionStorage.setItem('viewCart', true);

    // setting test price to B, user is a member
    window.location.href = `${cart.cartBaseUrl}/cart/?productId=${aaaModal.productId}&groupId=${groupdId}&tp=B`;
  };

  // single club on the page
  if (!aaaModal.askForZipcode) {
    redirectToCart(groupId);
  }
  // multiple clubs on a page
  else if (club && aaaModal.askForZipcode) {
    groupId = club.groupId;
    redirectToCart(groupId);
  }
  // show error message
  else {
    errorMsgElMember.innerHTML = `<p class='errormsg'>This product is not available for your club.</p>`;
    pilotModal.show();
  }
};

const handleCancel = () => {
  console.log('Closing member verification modal...');
};

const handleLoaded = () => {
  console.log('Login light widget loaded...');
  pilotModal.hide();
};

const pilotModal = new bootstrap.Modal(document.getElementById('pilot-modal'), {
  backdrop: true,
});

// this is dependent on the cart.js methods
const aaaModal = {
  productId: null, // this is updated once the pilot modal is shown
  clubsOnPage: [], // this is updated once the pilot modal is shown
  askForZipcode: false, // for non-member only, ask for zipcode if page is for multiple clubs
  showModal: true,
  cookieGroupId: cart.readCookie('_up4gid'),

  init: (params) => {
    const { clubNames } = params;

    // set valid clubs for this page/modal
    aaaModal.clubsOnPage = clubs.filter((club) => clubNames.includes(club.ClubName));

    // get valid clubName using groupId if it exist
    const validClubName = clubs.find((club) => club.groupId === aaaModal.cookieGroupId)?.ClubName;

    // set show/hide modal if cookie groupId is for one of the valid clubs for the page/modal
    aaaModal.showModal = !aaaModal.clubsOnPage.some((club) => club.ClubName === validClubName);

    const withZipEl = document.querySelector('#with-zip');
    const noZipEl = document.querySelector('#no-zip');

    if (aaaModal.clubsOnPage.length > 1) {
      aaaModal.askForZipcode = true;

      withZipEl.classList.remove('d-none');
      noZipEl.classList.add('d-none');
    }
    // set groupId using the only club for this page so that non-members doesn't need to enter zipcode
    else {
      aaaModal.cookieGroupId = aaaModal.clubsOnPage[0].groupId;
      // cart.setCookie('_up4gid', aaaModal.clubs[0].groupId);

      withZipEl.classList.add('d-none');
      noZipEl.classList.remove('d-none');
    }

    aaaModal.initAddBtnEvent();
    aaaModal.initAddToCartBtns();
    aaaModal.initModalBtns();
  },

  initModalBtns: () => {
    const submitZipBtn = document.querySelector('#member_login #submitZip');
    const showLoginLight = document.querySelector('#member_login #validateMember');

    const handleNonMember = () => {
      const zipcode = document.querySelector('#member_login #zipcode').value;
      const zipClub = aaaModal.clubsOnPage.find((club) => club.ZipCodes.includes(zipcode));
      const groupId = aaaModal.cookieGroupId || zipClub?.groupId;

      if ((!zipcode || zipcode.length < 5) && aaaModal.askForZipcode) {
        errorMsgElNonMember.innerHTML = `<p class='errormsg'>Please enter or check your zip code.</p>`;
        return;
      }

      if (zipClub || !aaaModal.askForZipcode) {
        errorMsgElNonMember.innerHTML = '';

        // cart.setCookie('isMembershipValidated', 'validated');

        // clearing the existing cart data
        cart.deleteCookie('cartId');
        sessionStorage.removeItem('localCart');
        sessionStorage.setItem('viewCart', true);

        // setting test price to A, user is not a member
        window.location.href = `${cart.cartBaseUrl}/cart/?productId=${aaaModal.productId}&groupId=${groupId}&tp=A`;
      }
      // show error message
      else {
        errorMsgElNonMember.innerHTML = `<p class='errormsg'>This product is not available for your zip code.</p>`;
      }
    };

    submitZipBtn.addEventListener('click', handleNonMember, true);

    showLoginLight.addEventListener(
      'click',
      () => {
        // showing login light modal from AAA
        showMemberValidationForm(handleSuccess, handleCancel, handleLoaded);

        // for testing locally only
        // handleSuccess({
        //   number: '4290140000000000',
        //   club: '049', // 215/049
        //   level: 'Plus',
        //   validationType: 'Member Name',
        //   vendor: 'certus',
        // });
      },
      true
    );
  },

  initAddBtnEvent: () => {
    document.addEventListener(
      'click',
      (event) => {
        const eventElement = event.target;
        const addToCartBtn = eventElement.closest('a[data-pilot-pid]');
        const productId = addToCartBtn ? addToCartBtn.dataset?.pilotPid : null;

        // storing/updating global productId used to trigger the modal
        if (productId) {
          aaaModal.productId = productId;
        }

        event.preventDefault();

        // stop processing code below if the click did not come from a pilot CTA
        if (!addToCartBtn) {
          return;
        }

        // if membership is not verified show the membership validation modal
        if (aaaModal.showModal) {
          // show membership validation modal
          pilotModal.show();
        }
        // continue
        else {
          console.log('Allow to continue');
          // upadate groupId and tp query string of the CTA

          // setting test price to B, user is a member
          window.location.href = `${cart.cartBaseUrl}/cart/?productId=${aaaModal.productId}&groupId=${aaaModal.cookieGroupId}&tp=B`;
        }
      },
      true
    );
  },

  initAddToCartBtns: () => {
    const addToCartBtns = document.querySelectorAll('a[data-pilot-pid]');
    const cartCookie = cart.readCookie('cartId');
    const testPrice = cart.readCookie('_up4tp') ? `&tp=${cart.readCookie('_up4tp')}` : '';
    const couponCode = cart.readCookie('_up4cc') ? `&coupon=${cart.readCookie('_up4cc')}` : '';
    const groupId = cart.readCookie('_up4gid') ? `&groupId=${cart.readCookie('_up4gid')}` : '';
    const localCart = cart.getLocalCart();

    for (let addToCartBtn of addToCartBtns) {
      const productId = addToCartBtn.dataset.pilotPid;
      const cartFlow = addToCartBtn.dataset.cart;
      const language = addToCartBtn.lang;

      addToCartBtn.classList.remove('btn-processing');

      // if CTA's product is already in the local cart and there's an existing cart
      if (localCart?.cartProducts.some((product) => product.productId === productId) && cartCookie) {
        // addToCartBtn.innerHTML = 'View In Cart';
        if (cartFlow === 'add' || localCart?.cartProducts.length === 1) {
          if (cartFlow === 'add') {
            addToCartBtn.innerHTML = language.includes('es-') ? 'Ver en carro' : 'View in Cart';
          }
          addToCartBtn.classList.add('view-cart-btn');
          addToCartBtn.href = `${cart.cartBaseUrl}/cart/?cartId=${cartCookie}${testPrice}${couponCode}${groupId}`;
        } else {
          addToCartBtn.classList.remove('view-cart-btn');
          addToCartBtn.href = `${cart.cartBaseUrl}/cart/?productId=${productId}${testPrice}${couponCode}${groupId}`;
        }
      }
      // if CTA's product is not in the local cart
      else {
        if (cartFlow === 'add') {
          addToCartBtn.innerHTML = language.includes('es-') ? 'Añadir al carro' : 'Add to Cart';
        }
        addToCartBtn.classList.remove('view-cart-btn');
        addToCartBtn.href = `${cart.cartBaseUrl}/cart/?productId=${productId}${testPrice}${couponCode}${groupId}`;
      }
    }
  },
};
